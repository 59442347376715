body {
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #333;
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #333;
}

.silent-iframe {
    border-width: 0;
    position: absolute;
    top: 0;
    height: 0;
    width: 0;
    overflow: hidden;
}

.app-layout {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.app-container {
    overflow: auto;
    flex-grow: 1;
    display: -ms-flexbox;
    display: flex;
    position: relative;
}

.left-container {
    min-width: 300px;
    width: 300px;
}

.right-container {
    min-width: 300px;
    width: 300px;
}

.center-container {
    display: flex;
    flex-grow: 1;
}

.button-container {
    position: absolute;
    left: 8px;
    bottom: 8px;
}

.button-form {
    font-size: 12px;
    font-weight: 600;
    height: 72px;
    width: 72px;
}

.order-panel {
    background-color: #fff;
    border-radius: 8px;
    flex-grow: 1;
    margin: 8px 4px;
    padding: 8px;
    overflow: auto;
}

.price-paper {
    box-shadow: none;
    height: 24px;
    width: 70px;
    padding: 5px;
    text-align: center;
}

.points-paper {
    box-shadow: none;
    height: 24px;
    width: 28px;
    padding: 5px;
    text-align: center;
}

.wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
.text {
    color: #fbae17;
    display: inline-block;
    margin-left: 5px;
}